import React, { useEffect, useState, useRef } from "react";
import "./UserNavbar.css";
import logo from "../../../assets/images/riso_hire_logo(new).png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { logout, setCredentials } from "../../../slice/authSlice";
import { useNavigate } from "react-router-dom";
import { useJobAllQuery, useJobDetQuery } from "../../../slice/authApiSlice";
import { setJobData } from "../../../slice/jobSlice";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import FeedbackIcon from '@mui/icons-material/Feedback';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { NotificationAddOutlined, Notifications, NotificationsNoneOutlined, Search } from "@mui/icons-material";
import Notification from "./Notification";
import Feedback from "../../../components/modal/Feedback";
import { generatePublicUrl } from "../../../constants";
import SearchComponent from "./SearchComponent";


const UserNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: jobDetails, isLoading, isError, refetch } = useJobAllQuery();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [feedbackOpener, setFeedbackOpener] = useState(false);
  const [showNotificationData, setShowNotificationData] = useState(false);
  const [showSearchData, setSearchData] = useState(false);
  const [state, setState] = React.useState({
    left: false,
  });
  const dropdownRef = useRef(null);

  const user = useSelector((state) => state.auth.userInfo);
  const token = useSelector((state) => state.auth.token);
  const jobs = useSelector((state) => state.job.jobData);
  const { data:jobDet, isLoading: isLoadingjobDet, error: jobError } = useJobDetQuery(user._id, {
    skip: !user
});
  const [pollingInterval, setPollingInterval] = useState(1000); // Start with 1 second


  const handleFeedbackOpener = () => {
    setFeedbackOpener(!feedbackOpener);
    setDropdownOpen(false)
  };


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };



  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
            <div className="user_navbar_profile_section">
            {user?.profilePictures.length === 0 ?   <p className="prof_icon">{user.firstName[0] + user.lastName[0]}</p> : 
            
            <img className="profile_icon_img" src={generatePublicUrl(user?.profilePictures[0].img)} />
            }
          </div>
            </ListItemIcon>
            <ListItemText
              primary={user?.firstName + user?.lastName}
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => handleNavigate("/profile")}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary={"View Profile"}
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => handleNavigate("/jobs")}>
            <ListItemIcon>
              <CorporateFareOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Latest jobs"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => handleNavigate("/saved-jobs")}>
            <ListItemIcon>
              <FolderSpecialOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Saved jobs"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => handleNavigate("/applied-jobs")}>
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Applied jobs"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={logoutHandler}>
            <ListItemIcon>
              <AddCommentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Add feedback"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={logoutHandler}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );








  const config = {
    cUrl: 'https://api.risohire.com/api/profile-data'
  };

  // Handle clicking outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Poll for user data
  useEffect(() => {
    if (user?.role !== "user") return;

    let isMounted = true;
    let controller = new AbortController();

    const fetchUserData = async () => {
      try {
        const response = await fetch(config.cUrl, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
          signal: controller.signal,
        });

        if (response.ok && isMounted) {
          const data = await response.json();

          if (JSON.stringify(data) !== JSON.stringify(user)) {
            dispatch(setCredentials({ userInfo: data, token }));
            setPollingInterval(1000);
          } else {
            setPollingInterval((prevInterval) => Math.min(prevInterval * 2, 30000));
          }
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Failed to fetch user data:", error);
        }
      }
    };

    const intervalId = setInterval(fetchUserData, pollingInterval);

    return () => {
      isMounted = false;
      controller.abort();
      clearInterval(intervalId);
    };
  }, [dispatch, user, token, pollingInterval]);

  // Handle job data updates
  useEffect(() => {
    if (user?.role !== "user") {
        // Ensure jobDet is not empty or undefined
        if (jobDet && Object.keys(jobDet).length > 0) {
          dispatch(setJobData(jobDet));
        }
  
    }else{
      const updateJobData = () => {
        if (jobDetails && !isLoading && !isError) {
          dispatch(setJobData(jobDetails));
          // console.log("ghdssssssssssssssssssssssssssssssssssssssm");
          
        } else if (isError) {
          console.error("Failed to fetch job details.");
        }
      };
  
      updateJobData();
      const intervalId = setInterval(refetch, 30000);
  
      return () => clearInterval(intervalId);
    }
 
  }, [dispatch, jobDetails, isLoading, isError, refetch, jobDet]);

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const handleNavigate = (path) => {
    navigate(path);
    if (path) {
      setDropdownOpen(false);
    }
  };

  let userId = user?._id;


  useEffect(() => {
    if (!userId) {
      // console.log('User not connected');
      return;
    }

    const fetchNotifications = async () => {
      try {
        setNotifications(user?.notifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [userId]);

  useEffect(() => {

    if (!userId) {
      // console.log("not connected");
      
    }

    // console.log("enter");
    

    // Create a new EventSource to listen for notifications
    const eventSource = new EventSource(`https://api.risohire.com/api/notifications/stream/${userId}`);

    // console.log(eventSource);
    

    // On receiving a notification event
    eventSource.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);
      // console.log('New Notification:', newNotification);
      setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
    };

    // On connection error
    eventSource.onerror = () => {
      console.error("Error connecting to the notifications stream.");
      eventSource.close();
    };

    // Clean up the connection when the component unmounts
    return () => {
      eventSource.close();
    };
  }, [user?._id]);

  // console.log(notifications);
  const handleNotificationClick = () => {
    setShowNotificationData((prevState) => !prevState);
  };

  const handleMobSearchClick = () => {
    setSearchData(!showSearchData);
  }

  return (
    <>
       {feedbackOpener && (
          <Feedback
            onClose={handleFeedbackOpener}
            onSubmit={handleFeedbackOpener}
          />
        )}
    {showNotificationData && <Notification notification={notifications} />}
    {showSearchData && <SearchComponent closeModal={() => setSearchData(false)}/>}
    <div className="user_navbar_container">
      <div className="user_navbar_logo_container">
        <div className="user_navbar_logo" onClick={() => navigate("/")}>
          <img src={logo} alt="logo" />
        </div>
        <div className="user_navbar_link_container">
          <ul>
            <li>
              <a onClick={() => handleNavigate("/")}>Home</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="user_navbar_buttons_container">
    
       <button className="hideinmob">Riso Connects</button>
       <span className="notificationCont" onClick={handleNotificationClick}><button><NotificationsNoneOutlined className="ic-noti"/></button> <p>{user?.notifications?.length}</p></span>

        <div className="user_navbar_profile_section_container">
          <div className="user_navbar_profile_section">
          {user?.profilePictures.length === 0 ?   <p className="prof_icon">{user.firstName[0] + user.lastName[0]}</p> : 
            
            <img className="profile_icon_img" src={generatePublicUrl(user?.profilePictures[0].img)} />
            }
            <div className="user_name_section">
              <h4>{user.firstName + " " + user.lastName}</h4>
              <p style={{ textTransform: "capitalize" }}>{user?.location}</p>
            </div>
            <div className="arrown_down_icon_container" onClick={handleDropdownClick}>
              {!dropdownOpen ? (
                <FontAwesomeIcon icon={faAngleDown} />
              ) : (
                <FontAwesomeIcon icon={faAngleUp} />
              )}
            </div>
          </div>
          {dropdownOpen && (
            <div className="user_profile_dropdown_container" ref={dropdownRef}>
              <div className="user_profile_dropdown">
                <div className="dropdown_profile_section">
                  <div className="drop_profile_image">
                  {user?.profilePictures.length === 0 ?   <p className="prof_icon">{user.firstName[0] + user.lastName[0]}</p> : 
            
            <img className="profile_icon_img1" src={generatePublicUrl(user?.profilePictures[0].img)} />
            }
                  </div>
                  <div className="drop_profile_details">
                    <h4>{user.firstName + " " + user.lastName}</h4>
                    <p>{user.email}</p>
                  </div>
                </div>
                <div className="drop_nav_list">
                  <ul>
                    <li><a onClick={() => handleNavigate("/profile")}>View Profile</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                    {user?.role === "user" ? <> <li><a onClick={() => handleNavigate("/jobs")}>Latest Jobs</a></li>
                    <li><a onClick={() => handleNavigate("/applied-jobs")}>Applied Jobs</a></li>
                    <li><a onClick={() => handleNavigate("/saved-jobs")}>Saved Jobs</a></li></> : null}
                    <li><a onClick={handleFeedbackOpener}>Add Feedback</a></li>
                    <li><a onClick={logoutHandler}>Logout</a></li>

                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>



    <div className="mobile_nav_container123">
      <div className="mobile_nav_logo_section123" onClick={() => navigate("/")}>
        <img src={logo} alt="logo" />
      </div>
      <div>
        <React.Fragment>
          <div className="mob_Icons_arrange">
          <Search className="mob_search_all" onClick={handleMobSearchClick}/>
          <span className="notificationCont" onClick={handleNotificationClick}><button><NotificationsNoneOutlined className="ic-noti"/></button> <p>{user?.notifications?.length}</p></span>
          <MenuIcon onClick={toggleDrawer("left", true)} />
          </div>
          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
        </React.Fragment>
      </div>
    </div>

    </>
  );
};

export default UserNavbar;
