import React, { useEffect, useState } from "react";
import "../forgot/Forgot.css";
import logo from "../../../assets/images/hire_form_logo.png";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
import { useResetPasswordMutation } from "../../../slice/authApiSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CandRegister from "../../register/candidate/CandRegister";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import MobileCandidateReg from "../../register/candidate/mcandidate/MobileCandidateReg";
import { toast } from "react-toastify";

const Reset = ({ onClose, onSubmit }) => {
  const [busy, setBusy] = useState(true);
  const [success, setSuccess] = useState(false);
  const [invalidUser, setInvaliduser] = useState('');
  const [newPassword, setNewPassword] = useState({
    password: '',
    confirmPassword: ''
  });
  const [errorMsg, setErrorMsg] = useState('');
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [candModalOpen, setCandModalOpen] = useState(false);
  const token = searchParams.get('token');
  const id = searchParams.get('id');

  const verifyToken = async () => {
    try {
      const { data } = await axios.get(`https://api.risohire.com/api/verify-token?token=${token}&id=${id}`);
      setBusy(false);
      console.log(data);
    } catch (error) {
      if (error?.response?.data) {
        const { data } = error.response;
        if (!data.success) return setInvaliduser(data.error);
      }
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setNewPassword({ ...newPassword, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { password, confirmPassword } = newPassword;
    if (password.trim().length < 6 || password.trim().length > 20) {
      return setErrorMsg('Password must be 8 to 20 characters long');
    }
    if (password !== confirmPassword) {
      return setErrorMsg('Passwords do not match');
    }

    try {
      setBusy(true);
      const { data } = await axios.post(`https://api.risohire.com/api/reset-password?token=${token}&id=${id}`, { password });
      setBusy(false);
      
      if (data.success) {
        setSuccess(true);
        toast.success("This is to confirm that the password for your account has been successfully changed.")
      }
    } catch (error) {
      setBusy(false);
      if (error?.response?.data) {
        const { data } = error?.response;
        if (data.success === false) {
          setErrorMsg(data.error);
          toast.error("something went wrong");
        }
      }
    }
  };

  if (success) {
    return (
      <div className='hedRecetBN'>
        <h1>Password reset successful</h1>
        <button onClick={navigate("/")}>Go to home page</button>
      </div>
    );
  }
  if (invalidUser) {
    return <h1>{invalidUser}</h1>;
  }
  if (busy) {
    return <h1 className='hedRecet'>Wait for a moment, verifying reset token...</h1>;
  }

  const handleCandModalClick = () => {
    setCandModalOpen(!candModalOpen);
  };

  return (
    <div className="cand_login_section">
      {candModalOpen && (
        <CandRegister onClose={handleCandModalClick} onSubmit={handleCandModalClick} />
      )}
      <div className="cand_login_container">
        <div className="cand_login_left_side">
          <div className="cand_login_heading_section">
            <div className="logo_img_container">
              <img src={logo} alt="logo" />
            </div>
            <div className="cand_login_heading_container">
              <h2>Explore 5 lakh+ career opportunities.</h2>
            </div>
          </div>
          <div className="pnt_container">
            <p>Privacy Policy</p>
            <p>Terms & Conditions</p>
          </div>
        </div>
        <div className="cand_login_right_side">
          <div className="close_button_container">
            <button>
              <FontAwesomeIcon
                icon={faXmark}
                className="cross_icon"
                onClick={() => onClose()}
              />
            </button>
          </div>
          <div className="rightside_heading_and_form_container">
            <h2>Reset your password!</h2>
            <div className="cand_login_right_side_form_container">
              <form className="cand_login_right_side_form" onSubmit={handleSubmit}>
                <div className="cand_login_right_side_form_input_container">
                  <label>Enter new password</label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={newPassword.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="cand_login_right_side_form_input_container">
                  <label>Enter confirm password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={newPassword.confirmPassword}
                    onChange={handleChange}
                  />
                </div>
                {errorMsg && <p className="error_msg">{errorMsg}</p>}
                <div className="cand_login_button_container">
                  <button type="submit">Reset</button>
                </div>
              </form>
            </div>
            <div className="create_account_section">
              <p>Don’t have an account?</p>
              <button onClick={handleCandModalClick}>Create an Account</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
